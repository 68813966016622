"use client";
import { ErrorPage } from "@/components/common/error/error-page";
import { generatedResources } from "@/app/i18n/src";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const resources = generatedResources["fr-FR"];

  const messages = {
    info: resources.common.error.info(),
    tryANewSearch: resources.common.error.tryAgain(),
  };

  return <ErrorPage messages={messages} error={error} reset={reset} />;
}
