export async function logClientError(error: Error & { digest?: string }) {
  try {
    const { message, digest, name, cause, stack } = error || {};

    fetch("/api/log-client-error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message,
        digest,
        name,
        cause,
        stack,
        referer: document.referrer,
      }),
    });
  } catch (e) {}
}
